import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Money Transfer App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/money-transfer-mobile-app/"
    metaDescription="Design your own money transfer app today. To get started, simply sign up to Uizard Pro and select the template."
    description="
    h2:Introducing our money transfer app design template for mobile
    <br/>
    Design your own money transfer app today with our brand-new <a:https://uizard.io/templates/mobile-app-templates/>mobile app design template</a> for money transfers. Our templates come with all the must-have features for any self-respecting money transfer app design built in. To get started, simply sign up to Uizard Pro and select the template.
    <br/>
    h3:Design your app quicker than converting currency
    <br/>
    Uizard is a <a:https://uizard.io/prototyping/>UI prototyping tool</a> designed for ease of use and to support you to design your own apps rapidly and collaboratively. Our templates come packed with all the must-have features and design considerations built in, meaning you can mockup your own app design in next to no time.
    <br/>
    h3:Transfer ideas between you and team with the click of a button
    <br/>
    Collaboration on your app idea has never been so easy. Share your Uizard project with your teammates, external stakeholders, potential investors, you can even share with prospective customers.
    "
    pages={[
      "Secure login screen mockup with PIN code interface",
      "Personal account overview screen",
      "Money transfer user flow",
      "Contact list mockup",
    ]}
    projectCode="QrpO5n7LBwT3GanmP9wd"
    img1={data.image1.childImageSharp}
    img1alt="money transder app design overview"
    img2={data.image2.childImageSharp}
    img2alt="money transder app design send money screen"
    img3={data.image3.childImageSharp}
    img3alt="money transder app design account screen"
    img4={data.image4.childImageSharp}
    img4alt="money transder app design contacts screen"
    img5={data.image5.childImageSharp}
    img5alt="money transder app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/money-transfer-mobile-app/money-transfer-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/money-transfer-mobile-app/money-transfer-app-send.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/money-transfer-mobile-app/money-transfer-app-account.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/money-transfer-mobile-app/money-transfer-app-contacts.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/money-transfer-mobile-app/money-transfer-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
